<template>
  <div class="container">
    <div class="inner">
      <div v-if="isOrganizationUser" class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
          <label><input class="uk-radio" type="radio" v-model="isRequestAll" v-bind:value="true" @change="onRadioChanged"> 所属組織の全履歴</label>
          <label><input class="uk-radio" type="radio" v-model="isRequestAll" v-bind:value="false" @change="onRadioChanged"> 自分の履歴のみ</label>
      </div>
      <div v-if="usages.length < 1" class="justify-center" uk-spinner="ratio:1" />
      <UsagePaging :direction="'latest'" v-if="usages.length > 0">最新情報を読込</UsagePaging>
      <UsageListHolder v-for="usage in usages" :key="usage.id" :usage="usage" />
      <UsagePaging :direction="'oldest'" v-if="usages.length > 0">過去情報を読込</UsagePaging>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onBeforeUpdate, ref } from "vue";
import UsagePaging from "../atoms/UsagePaging.vue";
import UsageListHolder from "../atoms/UsageListHolder.vue";
import { useUsage } from "../compositions/useUsage";
import {
  LocationQuery,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRoute,
} from "vue-router";
import { useNotification } from "../compositions/useNotification";
import { MyApi } from "../../autogen";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "Home",
  components: { UsageListHolder, UsagePaging },
  setup() {
    // reactive objects
    const usages = useUsage();
    // 論文の取得処理
    // パスのmodeにenterpriseが指定されており、且つuserIdが指定されている時は
    // エンタープライズ用のAPIへリクエストを投げる
    const getPapers = async (query: LocationQuery) => {
      const profile = await new MyApi().getMyProfile({});

      // パスの確認
      const isEnterprise = query.mode === "enterprise";
      usages.isOrganizationUser.value = !!profile.data.organization && !isEnterprise;
      const mode = isEnterprise ? "enterprise" : "my";
      let userId: number | null = null;
      if (query.userId && !Array.isArray(query.userId)) {
        userId = parseInt(query.userId);
      }
      // リストの更新
      const page = 0;
      usages.clear();
      usages.load(mode, userId, page);
    };
    // laod papers
    const locationQuery = useRoute().query;
    onBeforeMount(async () => await getPapers(locationQuery));
    onBeforeRouteUpdate((to, from, next) => {
      getPapers(to.query);
      next();
    });
    const onRadioChange = async () => await getPapers(locationQuery);
    return {
      usages: usages.list,
      isOrganizationUser: usages.isOrganizationUser,
      isRequestAll: usages.isRequestAll,
      onRadioChanged: onRadioChange,
    };
  },
});
</script>

<style scoped>
.container {
  display: block;
  margin: 15px 0px;
  width: 90vw !important;
}
.inner {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
