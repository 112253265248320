/* tslint:disable */
/* eslint-disable */
/**
 * 1paper APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import Store from '../store';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ConfirmRetranslationResponse
 */
export interface ConfirmRetranslationResponse {
    /**
     * 
     * @type {number}
     * @memberof ConfirmRetranslationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfirmRetranslationResponse
     */
    'state': string;
}
/**
 * ※ エンタープライズの翻訳回数更新時の回数用。コメント見る感じ、おそらく一時対応用。Membership にまとめちゃっても良さそう。
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * ユーザ ID（`User.id`）
     * @type {number}
     * @memberof Contract
     */
    'user_id': number;
    /**
     * 翻訳回数更新時の回数。エンタープライズは毎月この回数にリセットされる。<br/> -1 の場合は翻訳回数無制限。
     * @type {number}
     * @memberof Contract
     */
    'quota': number;
}
/**
 * 
 * @export
 * @interface EvaluateTranslationRequest
 */
export interface EvaluateTranslationRequest {
    /**
     * 
     * @type {number}
     * @memberof EvaluateTranslationRequest
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof EvaluateTranslationRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'id': number;
    /**
     * 翻訳履歴 ID（`Usage.id`）
     * @type {number}
     * @memberof Evaluation
     */
    'usage_id': number;
    /**
     * スコア（1 ～ 5）
     * @type {number}
     * @memberof Evaluation
     */
    'score'?: number;
    /**
     * コメント
     * @type {string}
     * @memberof Evaluation
     */
    'comment'?: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof Evaluation
     */
    'registered_at': string;
}
/**
 * 
 * @export
 * @interface GetEnterpriseQuota
 */
export interface GetEnterpriseQuota {
    /**
     * 
     * @type {number}
     * @memberof GetEnterpriseQuota
     */
    'consumedUsages'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetEnterpriseQuota
     */
    'contractedUsages'?: number;
}
/**
 * エンタープライズ管理画面で表示するユーザー情報の一覧用のモデル
 * @export
 * @interface GetEnterpriseUsers
 */
export interface GetEnterpriseUsers {
    /**
     * ユーザーID
     * @type {number}
     * @memberof GetEnterpriseUsers
     */
    'user_id': number;
    /**
     * ユーザーの表示名
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'username'?: string;
    /**
     * 氏名(姓)
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'first_name'?: string;
    /**
     * 氏名(名)
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'last_name'?: string;
    /**
     * 累積翻訳本数
     * @type {number}
     * @memberof GetEnterpriseUsers
     */
    'quota_sum'?: number;
    /**
     * 月間平均翻訳本数
     * @type {number}
     * @memberof GetEnterpriseUsers
     */
    'quota_avg'?: number;
    /**
     * 最近翻訳した論文のタイトル
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'latest_paper_title'?: string;
    /**
     * 最近翻訳した論文の概要
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'latest_paper_abstract'?: string;
    /**
     * 最近翻訳した論文のサムネイル
     * @type {string}
     * @memberof GetEnterpriseUsers
     */
    'latest_paper_image'?: string;
}
/**
 * 
 * @export
 * @interface GetMyPlan
 */
export interface GetMyPlan {
    /**
     * 
     * @type {string}
     * @memberof GetMyPlan
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetMyPlan
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetMyPlan
     */
    'quota'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyPlan
     */
    'has_annotation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyPlan
     */
    'is_ocr_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMyPlan
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyPlan
     */
    'applied'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMyPlan
     */
    'consuming_word_count'?: number;
    /**
     * 
     * @type {GetMyPlanStripe}
     * @memberof GetMyPlan
     */
    'stripe'?: GetMyPlanStripe;
    /**
     * 
     * @type {string}
     * @memberof GetMyPlan
     */
    'add_purchase_payment_code'?: string;
}
/**
 * 
 * @export
 * @interface GetMyPlanStripe
 */
export interface GetMyPlanStripe {
    /**
     * 
     * @type {string}
     * @memberof GetMyPlanStripe
     */
    'pk': string;
    /**
     * 
     * @type {string}
     * @memberof GetMyPlanStripe
     */
    'payment_code': string;
}
/**
 * 
 * @export
 * @interface GetMyProfileResponse
 */
export interface GetMyProfileResponse {
    /**
     * 
     * @type {Profile}
     * @memberof GetMyProfileResponse
     */
    'profile': Profile;
    /**
     * 
     * @type {GetMyProfileResponseMembership}
     * @memberof GetMyProfileResponse
     */
    'membership': GetMyProfileResponseMembership;
    /**
     * 
     * @type {string}
     * @memberof GetMyProfileResponse
     */
    'organization'?: string;
}
/**
 * 
 * @export
 * @interface GetMyProfileResponseMembership
 */
export interface GetMyProfileResponseMembership {
    /**
     * 
     * @type {number}
     * @memberof GetMyProfileResponseMembership
     */
    'quota': number;
    /**
     * 
     * @type {string}
     * @memberof GetMyProfileResponseMembership
     */
    'contract_renewal_date': string;
    /**
     * 
     * @type {GetMyProfileResponseMembershipPlan}
     * @memberof GetMyProfileResponseMembership
     */
    'plan': GetMyProfileResponseMembershipPlan;
    /**
     * 
     * @type {number}
     * @memberof GetMyProfileResponseMembership
     */
    'contract'?: number;
}
/**
 * 
 * @export
 * @interface GetMyProfileResponseMembershipPlan
 */
export interface GetMyProfileResponseMembershipPlan {
    /**
     * コード
     * @type {string}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'code': string;
    /**
     * プラン名（日本語）
     * @type {string}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'name': string;
    /**
     * stripeで月額購入の識別用コード
     * @type {string}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'payment_code'?: string;
    /**
     * 翻訳回数更新時の回数（エンタープライズは *Contract* で管理）
     * @type {number}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'quota'?: number;
    /**
     * 原文注釈の有無
     * @type {boolean}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'has_annotation'?: boolean;
    /**
     * OCR の可否
     * @type {boolean}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'is_ocr_enabled'?: boolean;
    /**
     * 月額料金（表示用）
     * @type {number}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'price'?: number;
    /**
     * 翻訳文字数の上限。-1 だと無制限
     * @type {number}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'consuming_word_count'?: number;
    /**
     * 有効フラグ。無効（false）のものはプラン一覧に表示されない
     * @type {boolean}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'enabled'?: boolean;
    /**
     * stripeで回数追加の商品名
     * @type {string}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'add_purchase_product_name'?: string;
    /**
     * stripeで回数追加の識別用コード
     * @type {string}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'add_purchase_payment_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyProfileResponseMembershipPlan
     */
    'is_free'?: boolean;
}
/**
 * 
 * @export
 * @interface GetMyUsage
 */
export interface GetMyUsage {
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'translation_file': string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'translated_file'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'uploaded_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'consumed_quota'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'consumed_word_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyUsage
     */
    'is_ocr_used'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'err_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'err_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'api_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'source_lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'target_lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'title_original'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'title_translated'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'abstract'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'user_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'user_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMyUsage
     */
    'save_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMyUsage
     */
    'total_chars'?: number;
}
/**
 * 
 * @export
 * @interface InquiryRequest
 */
export interface InquiryRequest {
    /**
     * 
     * @type {InquiryTypeEnum}
     * @memberof InquiryRequest
     */
    'type': InquiryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InquiryRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InquiryRequest
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof InquiryRequest
     */
    'usage_id'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InquiryTypeEnum = {
    AboutTranslationError: 'AboutTranslationError',
    AboutEnterprisePlan: 'AboutEnterprisePlan',
    AboutOthers: 'AboutOthers'
} as const;

export type InquiryTypeEnum = typeof InquiryTypeEnum[keyof typeof InquiryTypeEnum];


/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Membership
 */
export interface Membership {
    /**
     * ユーザ ID（`User.id`）
     * @type {number}
     * @memberof Membership
     */
    'user_id': number;
    /**
     * プランコード（`Plan.code`）
     * @type {string}
     * @memberof Membership
     */
    'plan_code': string;
    /**
     * 翻訳残回数
     * @type {number}
     * @memberof Membership
     */
    'quota'?: number;
    /**
     * stripe の決済完了時のコード（スタンダード・アカデミックのみ）
     * @type {string}
     * @memberof Membership
     */
    'subscription_code'?: string;
    /**
     * 翻訳回数の次回更新日時
     * @type {string}
     * @memberof Membership
     */
    'contract_renewal_date'?: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof Membership
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Membership
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 組織ID
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 組織名称
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * プランコード（`Plan.code`）
     * @type {string}
     * @memberof Organization
     */
    'plan_code': string;
    /**
     * stripe の決済完了時のコード（未使用）
     * @type {string}
     * @memberof Organization
     */
    'subscription_code'?: string;
    /**
     * 翻訳回数の次回更新日時
     * @type {string}
     * @memberof Organization
     */
    'contract_renewal_date'?: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof Organization
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Organization
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface OrganizationContract
 */
export interface OrganizationContract {
    /**
     * 
     * @type {number}
     * @memberof OrganizationContract
     */
    'id': number;
    /**
     * 組織 ID（`Organization.id`）
     * @type {number}
     * @memberof OrganizationContract
     */
    'organization_id': number;
    /**
     * 契約した翻訳回数
     * @type {number}
     * @memberof OrganizationContract
     */
    'quota': number;
    /**
     * 登録日時
     * @type {string}
     * @memberof OrganizationContract
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof OrganizationContract
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface OrganizationMembership
 */
export interface OrganizationMembership {
    /**
     * 
     * @type {number}
     * @memberof OrganizationMembership
     */
    'id': number;
    /**
     * 組織 ID（`Organization.id`）
     * @type {number}
     * @memberof OrganizationMembership
     */
    'organization_id': number;
    /**
     * ユーザ ID（`User.id`）
     * @type {number}
     * @memberof OrganizationMembership
     */
    'user_id': number;
    /**
     * 翻訳した回数
     * @type {number}
     * @memberof OrganizationMembership
     */
    'quota'?: number;
    /**
     * 登録日時
     * @type {string}
     * @memberof OrganizationMembership
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof OrganizationMembership
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * コード
     * @type {string}
     * @memberof Plan
     */
    'code': string;
    /**
     * プラン名（日本語）
     * @type {string}
     * @memberof Plan
     */
    'name': string;
    /**
     * stripeで月額購入の識別用コード
     * @type {string}
     * @memberof Plan
     */
    'payment_code'?: string;
    /**
     * 翻訳回数更新時の回数（エンタープライズは *Contract* で管理）
     * @type {number}
     * @memberof Plan
     */
    'quota'?: number;
    /**
     * 原文注釈の有無
     * @type {boolean}
     * @memberof Plan
     */
    'has_annotation'?: boolean;
    /**
     * OCR の可否
     * @type {boolean}
     * @memberof Plan
     */
    'is_ocr_enabled'?: boolean;
    /**
     * 月額料金（表示用）
     * @type {number}
     * @memberof Plan
     */
    'price'?: number;
    /**
     * 翻訳文字数の上限。-1 だと無制限
     * @type {number}
     * @memberof Plan
     */
    'consuming_word_count'?: number;
    /**
     * 有効フラグ。無効（false）のものはプラン一覧に表示されない
     * @type {boolean}
     * @memberof Plan
     */
    'enabled'?: boolean;
    /**
     * stripeで回数追加の商品名
     * @type {string}
     * @memberof Plan
     */
    'add_purchase_product_name'?: string;
    /**
     * stripeで回数追加の識別用コード
     * @type {string}
     * @memberof Plan
     */
    'add_purchase_payment_code'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlanEnum = {
    Free: 'free',
    Trial: 'trial',
    Academic: 'academic',
    Standard: 'standard',
    Enterprise: 'enterprise'
} as const;

export type PlanEnum = typeof PlanEnum[keyof typeof PlanEnum];


/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof Profile
     */
    'username': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Profile
     */
    'email': string;
    /**
     * 名
     * @type {string}
     * @memberof Profile
     */
    'first_name'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof Profile
     */
    'last_name'?: string;
    /**
     * 所属
     * @type {string}
     * @memberof Profile
     */
    'belongs_to'?: string;
    /**
     * 住所
     * @type {string}
     * @memberof Profile
     */
    'address'?: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof Profile
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Profile
     */
    'updated_at': string;
    /**
     * 権限種別（`Role.name`）
     * @type {string}
     * @memberof Profile
     */
    'role_name'?: string;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'belongs_to': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RemindRequest
 */
export interface RemindRequest {
    /**
     * 
     * @type {string}
     * @memberof RemindRequest
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface RequestVerificationRequest
 */
export interface RequestVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestVerificationRequest
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface ResetRequest
 */
export interface ResetRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 権限名
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 登録日時
     * @type {string}
     * @memberof Role
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Role
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface StripeAddPurchaseRequest
 */
export interface StripeAddPurchaseRequest {
    /**
     * 
     * @type {boolean}
     * @memberof StripeAddPurchaseRequest
     */
    'livemode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeAddPurchaseRequest
     */
    'type'?: StripeAddPurchaseRequestTypeEnum;
    /**
     * 
     * @type {StripeAddPurchaseRequestData}
     * @memberof StripeAddPurchaseRequest
     */
    'data'?: StripeAddPurchaseRequestData;
}

export const StripeAddPurchaseRequestTypeEnum = {
    PaymentIntentSucceeded: 'payment_intent.succeeded'
} as const;

export type StripeAddPurchaseRequestTypeEnum = typeof StripeAddPurchaseRequestTypeEnum[keyof typeof StripeAddPurchaseRequestTypeEnum];

/**
 * 
 * @export
 * @interface StripeAddPurchaseRequestData
 */
export interface StripeAddPurchaseRequestData {
    /**
     * 
     * @type {StripeAddPurchaseRequestDataObject}
     * @memberof StripeAddPurchaseRequestData
     */
    'object'?: StripeAddPurchaseRequestDataObject;
}
/**
 * 
 * @export
 * @interface StripeAddPurchaseRequestDataObject
 */
export interface StripeAddPurchaseRequestDataObject {
    /**
     * 
     * @type {string}
     * @memberof StripeAddPurchaseRequestDataObject
     */
    'customer': string;
    /**
     * 
     * @type {string}
     * @memberof StripeAddPurchaseRequestDataObject
     */
    'receipt_email': string;
    /**
     * 
     * @type {number}
     * @memberof StripeAddPurchaseRequestDataObject
     */
    'amount_received': number;
    /**
     * 
     * @type {string}
     * @memberof StripeAddPurchaseRequestDataObject
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface StripeRequest
 */
export interface StripeRequest {
    /**
     * 
     * @type {boolean}
     * @memberof StripeRequest
     */
    'livemode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeRequest
     */
    'type'?: StripeRequestTypeEnum;
    /**
     * 
     * @type {StripeRequestData}
     * @memberof StripeRequest
     */
    'data'?: StripeRequestData;
}

export const StripeRequestTypeEnum = {
    InvoicePaid: 'invoice.paid'
} as const;

export type StripeRequestTypeEnum = typeof StripeRequestTypeEnum[keyof typeof StripeRequestTypeEnum];

/**
 * 
 * @export
 * @interface StripeRequestData
 */
export interface StripeRequestData {
    /**
     * 
     * @type {StripeRequestDataObject}
     * @memberof StripeRequestData
     */
    'object'?: StripeRequestDataObject;
}
/**
 * 
 * @export
 * @interface StripeRequestDataObject
 */
export interface StripeRequestDataObject {
    /**
     * 
     * @type {string}
     * @memberof StripeRequestDataObject
     */
    'customer': string;
    /**
     * 
     * @type {string}
     * @memberof StripeRequestDataObject
     */
    'customer_email': string;
    /**
     * 
     * @type {string}
     * @memberof StripeRequestDataObject
     */
    'subscription': string;
    /**
     * 
     * @type {StripeRequestDataObjectLines}
     * @memberof StripeRequestDataObject
     */
    'lines': StripeRequestDataObjectLines;
}
/**
 * 
 * @export
 * @interface StripeRequestDataObjectLines
 */
export interface StripeRequestDataObjectLines {
    /**
     * 
     * @type {Array<StripeRequestDataObjectLinesDataInner>}
     * @memberof StripeRequestDataObjectLines
     */
    'data': Array<StripeRequestDataObjectLinesDataInner>;
}
/**
 * 
 * @export
 * @interface StripeRequestDataObjectLinesDataInner
 */
export interface StripeRequestDataObjectLinesDataInner {
    /**
     * 
     * @type {StripeRequestDataObjectLinesDataInnerPeriod}
     * @memberof StripeRequestDataObjectLinesDataInner
     */
    'period': StripeRequestDataObjectLinesDataInnerPeriod;
    /**
     * 
     * @type {StripeRequestDataObjectLinesDataInnerPrice}
     * @memberof StripeRequestDataObjectLinesDataInner
     */
    'price': StripeRequestDataObjectLinesDataInnerPrice;
}
/**
 * 
 * @export
 * @interface StripeRequestDataObjectLinesDataInnerPeriod
 */
export interface StripeRequestDataObjectLinesDataInnerPeriod {
    /**
     * 
     * @type {number}
     * @memberof StripeRequestDataObjectLinesDataInnerPeriod
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof StripeRequestDataObjectLinesDataInnerPeriod
     */
    'end': number;
}
/**
 * 
 * @export
 * @interface StripeRequestDataObjectLinesDataInnerPrice
 */
export interface StripeRequestDataObjectLinesDataInnerPrice {
    /**
     * 
     * @type {string}
     * @memberof StripeRequestDataObjectLinesDataInnerPrice
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StripeRequestDataObjectLinesDataInnerPrice
     */
    'product': string;
}
/**
 * 
 * @export
 * @interface UpdateEnterpriseProfileRequest
 */
export interface UpdateEnterpriseProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'belongs_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnterpriseProfileRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMyProfileRequest
 */
export interface UpdateMyProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'belongs_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {number}
     * @memberof UploadFileResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface Usage
 */
export interface Usage {
    /**
     * 
     * @type {number}
     * @memberof Usage
     */
    'id': number;
    /**
     * ユーザ ID（`User.id`）
     * @type {number}
     * @memberof Usage
     */
    'user_id': number;
    /**
     * 翻訳対象の PDF ファイル名
     * @type {string}
     * @memberof Usage
     */
    'translation_file': string;
    /**
     * 翻訳対象の PDF のハッシュ値
     * @type {string}
     * @memberof Usage
     */
    'translation_hash'?: string;
    /**
     * 翻訳実行時のパラメータ
     * @type {string}
     * @memberof Usage
     */
    'translation_params': string;
    /**
     * 翻訳結果のファイル名（ファイル名自体は使われていない）
     * @type {string}
     * @memberof Usage
     */
    'translated_file'?: string;
    /**
     * 翻訳文字数
     * @type {number}
     * @memberof Usage
     */
    'consumed_word_count'?: number;
    /**
     * OCR 使用有無
     * @type {boolean}
     * @memberof Usage
     */
    'is_ocr_used'?: boolean;
    /**
     * エラー状態 0:その他 1:要OCR
     * @type {string}
     * @memberof Usage
     */
    'err_state'?: string;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof Usage
     */
    'err_message'?: string;
    /**
     * 翻訳元言語
     * @type {string}
     * @memberof Usage
     */
    'source_lang'?: string;
    /**
     * 翻訳先言語
     * @type {string}
     * @memberof Usage
     */
    'target_lang'?: string;
    /**
     * translateAPIのバージョン
     * @type {string}
     * @memberof Usage
     */
    'api_version'?: string;
    /**
     * 翻訳実行日時
     * @type {string}
     * @memberof Usage
     */
    'uploaded_at': string;
    /**
     * 翻訳完了日時（エラー終了時も含む）
     * @type {string}
     * @memberof Usage
     */
    'result_at'?: string;
    /**
     * 翻訳ステータス - *200* : 完了（ダウンロード済み） - *201* : 完了（未ダウンロード） - *202* : 翻訳中 - *40x* : エラー
     * @type {number}
     * @memberof Usage
     */
    'status'?: number;
    /**
     * 翻訳回数の消費回数（※ 現状未使用。10 万文字毎のカウントアップに使用する予定）
     * @type {number}
     * @memberof Usage
     */
    'consumed_quota'?: number;
    /**
     * 翻訳対象のタイトル（原文）
     * @type {string}
     * @memberof Usage
     */
    'title_original'?: string;
    /**
     * 翻訳対象のタイトル（訳文）
     * @type {string}
     * @memberof Usage
     */
    'title_translated'?: string;
    /**
     * 翻訳対象の abstract（訳文）
     * @type {string}
     * @memberof Usage
     */
    'abstract'?: string;
    /**
     * 翻訳対象のサムネイル用画像
     * @type {string}
     * @memberof Usage
     */
    'image'?: string;
    /**
     * 保存名
     * @type {string}
     * @memberof Usage
     */
    'save_name'?: string;
    /**
     * 総文字数
     * @type {number}
     * @memberof Usage
     */
    'total_chars'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 名
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 所属
     * @type {string}
     * @memberof User
     */
    'belongs_to'?: string;
    /**
     * 住所
     * @type {string}
     * @memberof User
     */
    'address'?: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof User
     */
    'registered_at': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof User
     */
    'updated_at': string;
    /**
     * 権限種別（`Role.name`）
     * @type {string}
     * @memberof User
     */
    'role_name'?: string;
    /**
     * ハッシュ化されたパスワード
     * @type {string}
     * @memberof User
     */
    'hash_password': string;
    /**
     * 認証済みフラグ。メールアドレス認証後に true になる
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
    /**
     * 有効フラグ。退会時の論理削除に使う（予定）
     * @type {boolean}
     * @memberof User
     */
    'enabled': boolean;
}

/**
 * EnterpriseApi - axios parameter creator
 * @export
 */
export const EnterpriseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a file of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEnterpriseUserFile: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('downloadEnterpriseUserFile', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('downloadEnterpriseUserFile', 'authorization', authorization)
            const localVarPath = `/enterprise/files/{usage_id}`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s profile in company
         * @param {number} userId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseProfile: async (userId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getEnterpriseProfile', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEnterpriseProfile', 'authorization', authorization)
            const localVarPath = `/enterprise/{user_id}/profile`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 消費済み本数と契約本数の取得を行うAPI
         * @summary Get consumed usages
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseQuota: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEnterpriseQuota', 'authorization', authorization)
            const localVarPath = `/enterprise/quota`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a enterprise user\'s usage history
         * @param {number} userId 
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUsages: async (userId: number, authorization: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getEnterpriseUsages', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEnterpriseUsages', 'authorization', authorization)
            const localVarPath = `/enterprise/{user_id}/usages`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a translated html url of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUserHtmlFileUrl: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('getEnterpriseUserHtmlFileUrl', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEnterpriseUserHtmlFileUrl', 'authorization', authorization)
            const localVarPath = `/enterprise/files/{usage_id}/html`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トークンに含まれるユーザー情報より組織IDを解決し、 組織メンバーの最近の利用状況を取得するためのAPI
         * @summary Get enterprise users list
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUsers: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getEnterpriseUsers', 'authorization', authorization)
            const localVarPath = `/enterprise/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateEnterpriseProfileRequest} updateEnterpriseProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnterpriseProfile: async (authorization: string, updateEnterpriseProfileRequest: UpdateEnterpriseProfileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateEnterpriseProfile', 'authorization', authorization)
            // verify required parameter 'updateEnterpriseProfileRequest' is not null or undefined
            assertParamExists('updateEnterpriseProfile', 'updateEnterpriseProfileRequest', updateEnterpriseProfileRequest)
            const localVarPath = `/enterprise/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEnterpriseProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnterpriseApi - functional programming interface
 * @export
 */
export const EnterpriseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnterpriseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download a file of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadEnterpriseUserFile(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadEnterpriseUserFile(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user\'s profile in company
         * @param {number} userId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseProfile(userId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseProfile(userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 消費済み本数と契約本数の取得を行うAPI
         * @summary Get consumed usages
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseQuota(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEnterpriseQuota>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseQuota(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a enterprise user\'s usage history
         * @param {number} userId 
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseUsages(userId: number, authorization: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMyUsage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseUsages(userId, authorization, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a translated html url of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseUserHtmlFileUrl(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseUserHtmlFileUrl(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * トークンに含まれるユーザー情報より組織IDを解決し、 組織メンバーの最近の利用状況を取得するためのAPI
         * @summary Get enterprise users list
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnterpriseUsers(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEnterpriseUsers>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnterpriseUsers(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateEnterpriseProfileRequest} updateEnterpriseProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnterpriseProfile(authorization: string, updateEnterpriseProfileRequest: UpdateEnterpriseProfileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnterpriseProfile(authorization, updateEnterpriseProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnterpriseApi - factory interface
 * @export
 */
export const EnterpriseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnterpriseApiFp(configuration)
    return {
        /**
         * 
         * @summary Download a file of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEnterpriseUserFile(usageId: number, authorization: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadEnterpriseUserFile(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s profile in company
         * @param {number} userId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseProfile(userId: number, authorization: string, options?: any): AxiosPromise<GetMyProfileResponse> {
            return localVarFp.getEnterpriseProfile(userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 消費済み本数と契約本数の取得を行うAPI
         * @summary Get consumed usages
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseQuota(authorization: string, options?: any): AxiosPromise<GetEnterpriseQuota> {
            return localVarFp.getEnterpriseQuota(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a enterprise user\'s usage history
         * @param {number} userId 
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUsages(userId: number, authorization: string, page?: number, options?: any): AxiosPromise<Array<GetMyUsage>> {
            return localVarFp.getEnterpriseUsages(userId, authorization, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a translated html url of same organization user
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUserHtmlFileUrl(usageId: number, authorization: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEnterpriseUserHtmlFileUrl(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * トークンに含まれるユーザー情報より組織IDを解決し、 組織メンバーの最近の利用状況を取得するためのAPI
         * @summary Get enterprise users list
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnterpriseUsers(authorization: string, options?: any): AxiosPromise<Array<GetEnterpriseUsers>> {
            return localVarFp.getEnterpriseUsers(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateEnterpriseProfileRequest} updateEnterpriseProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnterpriseProfile(authorization: string, updateEnterpriseProfileRequest: UpdateEnterpriseProfileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateEnterpriseProfile(authorization, updateEnterpriseProfileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnterpriseApi - object-oriented interface
 * @export
 * @class EnterpriseApi
 * @extends {BaseAPI}
 */
export class EnterpriseApi extends BaseAPI {
    /**
     * 
     * @summary Download a file of same organization user
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public downloadEnterpriseUserFile(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).downloadEnterpriseUserFile(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s profile in company
     * @param {number} userId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public getEnterpriseProfile(params: { userId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['userId'] = params['userId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).getEnterpriseProfile(_params.userId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 消費済み本数と契約本数の取得を行うAPI
     * @summary Get consumed usages
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public getEnterpriseQuota(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).getEnterpriseQuota(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a enterprise user\'s usage history
     * @param {number} userId 
     * @param {string} authorization 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public getEnterpriseUsages(params: { userId: number, authorization?: string, page?: number,  }, options?: any) {
				const _params = {} as any;
				_params['userId'] = params['userId']
				_params['authorization'] = params['authorization']
				_params['page'] = params['page']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).getEnterpriseUsages(_params.userId, _params.authorization, _params.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a translated html url of same organization user
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public getEnterpriseUserHtmlFileUrl(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).getEnterpriseUserHtmlFileUrl(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トークンに含まれるユーザー情報より組織IDを解決し、 組織メンバーの最近の利用状況を取得するためのAPI
     * @summary Get enterprise users list
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public getEnterpriseUsers(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).getEnterpriseUsers(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s profile
     * @param {string} authorization 
     * @param {UpdateEnterpriseProfileRequest} updateEnterpriseProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnterpriseApi
     */
    public updateEnterpriseProfile(params: { authorization?: string, updateEnterpriseProfileRequest: UpdateEnterpriseProfileRequest,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['updateEnterpriseProfileRequest'] = params['updateEnterpriseProfileRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return EnterpriseApiFp(this.configuration).updateEnterpriseProfile(_params.authorization, _params.updateEnterpriseProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyApi - axios parameter creator
 * @export
 */
export const MyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authenticate
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('auth', 'authorization', authorization)
            const localVarPath = `/my/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel the logged in user\'s plan
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMyPlan: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('cancelMyPlan', 'authorization', authorization)
            const localVarPath = `/my/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check translation status
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTranslationStatus: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('checkTranslationStatus', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('checkTranslationStatus', 'authorization', authorization)
            const localVarPath = `/my/files/{usage_id}/status`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirmation of the need for retranslation
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRetranslation: async (authorization: string, file: File, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('confirmRetranslation', 'authorization', authorization)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('confirmRetranslation', 'file', file)
            const localVarPath = `/my/files/retranslate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a file
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMyFile: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('downloadMyFile', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('downloadMyFile', 'authorization', authorization)
            const localVarPath = `/my/files/{usage_id}`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Evaluate translation
         * @param {string} authorization 
         * @param {number} usageId 
         * @param {EvaluateTranslationRequest} evaluateTranslationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluateTranslation: async (authorization: string, usageId: number, evaluateTranslationRequest: EvaluateTranslationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('evaluateTranslation', 'authorization', authorization)
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('evaluateTranslation', 'usageId', usageId)
            // verify required parameter 'evaluateTranslationRequest' is not null or undefined
            assertParamExists('evaluateTranslation', 'evaluateTranslationRequest', evaluateTranslationRequest)
            const localVarPath = `/my/usages/{usage_id}/evaluations`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluateTranslationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a translated html url
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyHtmlFileUrl: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('getMyHtmlFileUrl', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyHtmlFileUrl', 'authorization', authorization)
            const localVarPath = `/my/files/{usage_id}/html`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of plans a user can apply
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPlans: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyPlans', 'authorization', authorization)
            const localVarPath = `/my/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a logged in user\'s profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfile: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyProfile', 'authorization', authorization)
            const localVarPath = `/my/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a logged in user\'s usage record
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUsage: async (usageId: number, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'usageId' is not null or undefined
            assertParamExists('getMyUsage', 'usageId', usageId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyUsage', 'authorization', authorization)
            const localVarPath = `/my/usages/{usage_id}`
                .replace(`{${"usage_id"}}`, encodeURIComponent(String(usageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a logged in user\'s usage history
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUsages: async (authorization: string, page?: number, all?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyUsages', 'authorization', authorization)
            const localVarPath = `/my/usages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/my/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary logout
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('logout', 'authorization', authorization)
            const localVarPath = `/my/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remind password
         * @param {RemindRequest} remindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remind: async (remindRequest: RemindRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'remindRequest' is not null or undefined
            assertParamExists('remind', 'remindRequest', remindRequest)
            const localVarPath = `/my/remind`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remindRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset passsword
         * @param {string} authorization 
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset: async (authorization: string, resetRequest: ResetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('reset', 'authorization', authorization)
            // verify required parameter 'resetRequest' is not null or undefined
            assertParamExists('reset', 'resetRequest', resetRequest)
            const localVarPath = `/my/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateMyProfileRequest} updateMyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile: async (authorization: string, updateMyProfileRequest: UpdateMyProfileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMyProfile', 'authorization', authorization)
            // verify required parameter 'updateMyProfileRequest' is not null or undefined
            assertParamExists('updateMyProfile', 'updateMyProfileRequest', updateMyProfileRequest)
            const localVarPath = `/my/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file
         * @param {string} sourceLang 
         * @param {string} targetLang 
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMyFile: async (sourceLang: string, targetLang: string, authorization: string, file: File, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceLang' is not null or undefined
            assertParamExists('uploadMyFile', 'sourceLang', sourceLang)
            // verify required parameter 'targetLang' is not null or undefined
            assertParamExists('uploadMyFile', 'targetLang', targetLang)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('uploadMyFile', 'authorization', authorization)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadMyFile', 'file', file)
            const localVarPath = `/my/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sourceLang !== undefined) {
                localVarQueryParameter['source_lang'] = sourceLang;
            }

            if (targetLang !== undefined) {
                localVarQueryParameter['target_lang'] = targetLang;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyApi - functional programming interface
 * @export
 */
export const MyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary authenticate
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auth(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel the logged in user\'s plan
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelMyPlan(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelMyPlan(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check translation status
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTranslationStatus(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkTranslationStatus(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirmation of the need for retranslation
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmRetranslation(authorization: string, file: File, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmRetranslationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmRetranslation(authorization, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download a file
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMyFile(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMyFile(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Evaluate translation
         * @param {string} authorization 
         * @param {number} usageId 
         * @param {EvaluateTranslationRequest} evaluateTranslationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evaluateTranslation(authorization: string, usageId: number, evaluateTranslationRequest: EvaluateTranslationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evaluateTranslation(authorization, usageId, evaluateTranslationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a translated html url
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyHtmlFileUrl(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyHtmlFileUrl(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of plans a user can apply
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyPlans(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMyPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyPlans(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a logged in user\'s profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyProfile(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyProfile(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a logged in user\'s usage record
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUsage(usageId: number, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyUsage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyUsage(usageId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a logged in user\'s usage history
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUsages(authorization: string, page?: number, all?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMyUsage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyUsages(authorization, page, all, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary logout
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remind password
         * @param {RemindRequest} remindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remind(remindRequest: RemindRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remind(remindRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset passsword
         * @param {string} authorization 
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reset(authorization: string, resetRequest: ResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reset(authorization, resetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateMyProfileRequest} updateMyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyProfile(authorization: string, updateMyProfileRequest: UpdateMyProfileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyProfile(authorization, updateMyProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file
         * @param {string} sourceLang 
         * @param {string} targetLang 
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMyFile(sourceLang: string, targetLang: string, authorization: string, file: File, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMyFile(sourceLang, targetLang, authorization, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyApi - factory interface
 * @export
 */
export const MyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyApiFp(configuration)
    return {
        /**
         * 
         * @summary authenticate
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.auth(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel the logged in user\'s plan
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMyPlan(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelMyPlan(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check translation status
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTranslationStatus(usageId: number, authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkTranslationStatus(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirmation of the need for retranslation
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRetranslation(authorization: string, file: File, options?: any): AxiosPromise<ConfirmRetranslationResponse> {
            return localVarFp.confirmRetranslation(authorization, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a file
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMyFile(usageId: number, authorization: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadMyFile(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Evaluate translation
         * @param {string} authorization 
         * @param {number} usageId 
         * @param {EvaluateTranslationRequest} evaluateTranslationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluateTranslation(authorization: string, usageId: number, evaluateTranslationRequest: EvaluateTranslationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.evaluateTranslation(authorization, usageId, evaluateTranslationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a translated html url
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyHtmlFileUrl(usageId: number, authorization: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMyHtmlFileUrl(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of plans a user can apply
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPlans(authorization: string, options?: any): AxiosPromise<Array<GetMyPlan>> {
            return localVarFp.getMyPlans(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a logged in user\'s profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfile(authorization: string, options?: any): AxiosPromise<GetMyProfileResponse> {
            return localVarFp.getMyProfile(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a logged in user\'s usage record
         * @param {number} usageId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUsage(usageId: number, authorization: string, options?: any): AxiosPromise<GetMyUsage> {
            return localVarFp.getMyUsage(usageId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a logged in user\'s usage history
         * @param {string} authorization 
         * @param {number} [page] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUsages(authorization: string, page?: number, all?: boolean, options?: any): AxiosPromise<Array<GetMyUsage>> {
            return localVarFp.getMyUsages(authorization, page, all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary logout
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.logout(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remind password
         * @param {RemindRequest} remindRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remind(remindRequest: RemindRequest, options?: any): AxiosPromise<void> {
            return localVarFp.remind(remindRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset passsword
         * @param {string} authorization 
         * @param {ResetRequest} resetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset(authorization: string, resetRequest: ResetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reset(authorization, resetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s profile
         * @param {string} authorization 
         * @param {UpdateMyProfileRequest} updateMyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile(authorization: string, updateMyProfileRequest: UpdateMyProfileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateMyProfile(authorization, updateMyProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file
         * @param {string} sourceLang 
         * @param {string} targetLang 
         * @param {string} authorization 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMyFile(sourceLang: string, targetLang: string, authorization: string, file: File, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.uploadMyFile(sourceLang, targetLang, authorization, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MyApi - object-oriented interface
 * @export
 * @class MyApi
 * @extends {BaseAPI}
 */
export class MyApi extends BaseAPI {
    /**
     * 
     * @summary authenticate
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public auth(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).auth(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel the logged in user\'s plan
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public cancelMyPlan(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).cancelMyPlan(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check translation status
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public checkTranslationStatus(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).checkTranslationStatus(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirmation of the need for retranslation
     * @param {string} authorization 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public confirmRetranslation(params: { authorization?: string, file: File,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['file'] = params['file']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).confirmRetranslation(_params.authorization, _params.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a file
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public downloadMyFile(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).downloadMyFile(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Evaluate translation
     * @param {string} authorization 
     * @param {number} usageId 
     * @param {EvaluateTranslationRequest} evaluateTranslationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public evaluateTranslation(params: { authorization?: string, usageId: number, evaluateTranslationRequest: EvaluateTranslationRequest,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['usageId'] = params['usageId']
				_params['evaluateTranslationRequest'] = params['evaluateTranslationRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).evaluateTranslation(_params.authorization, _params.usageId, _params.evaluateTranslationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a translated html url
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public getMyHtmlFileUrl(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).getMyHtmlFileUrl(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of plans a user can apply
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public getMyPlans(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).getMyPlans(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a logged in user\'s profile
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public getMyProfile(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).getMyProfile(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a logged in user\'s usage record
     * @param {number} usageId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public getMyUsage(params: { usageId: number, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['usageId'] = params['usageId']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).getMyUsage(_params.usageId, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a logged in user\'s usage history
     * @param {string} authorization 
     * @param {number} [page] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public getMyUsages(params: { authorization?: string, page?: number, all?: boolean,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['page'] = params['page']
				_params['all'] = params['all']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).getMyUsages(_params.authorization, _params.page, _params.all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public login(params: { loginRequest: LoginRequest,  }, options?: any) {
				const _params = {} as any;
				_params['loginRequest'] = params['loginRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).login(_params.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary logout
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public logout(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).logout(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remind password
     * @param {RemindRequest} remindRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public remind(params: { remindRequest: RemindRequest,  }, options?: any) {
				const _params = {} as any;
				_params['remindRequest'] = params['remindRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).remind(_params.remindRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset passsword
     * @param {string} authorization 
     * @param {ResetRequest} resetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public reset(params: { authorization?: string, resetRequest: ResetRequest,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['resetRequest'] = params['resetRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).reset(_params.authorization, _params.resetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s profile
     * @param {string} authorization 
     * @param {UpdateMyProfileRequest} updateMyProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public updateMyProfile(params: { authorization?: string, updateMyProfileRequest: UpdateMyProfileRequest,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				_params['updateMyProfileRequest'] = params['updateMyProfileRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).updateMyProfile(_params.authorization, _params.updateMyProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file
     * @param {string} sourceLang 
     * @param {string} targetLang 
     * @param {string} authorization 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyApi
     */
    public uploadMyFile(params: { sourceLang: string, targetLang: string, authorization?: string, file: File,  }, options?: any) {
				const _params = {} as any;
				_params['sourceLang'] = params['sourceLang']
				_params['targetLang'] = params['targetLang']
				_params['authorization'] = params['authorization']
				_params['file'] = params['file']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return MyApiFp(this.configuration).uploadMyFile(_params.sourceLang, _params.targetLang, _params.authorization, _params.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Inquiry
         * @param {InquiryRequest} inquiryRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiry: async (inquiryRequest: InquiryRequest, authorization?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryRequest' is not null or undefined
            assertParamExists('inquiry', 'inquiryRequest', inquiryRequest)
            const localVarPath = `/inquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registration
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequest: RegisterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('register', 'registerRequest', registerRequest)
            const localVarPath = `/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a verification email
         * @param {RequestVerificationRequest} requestVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerification: async (requestVerificationRequest: RequestVerificationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestVerificationRequest' is not null or undefined
            assertParamExists('requestVerification', 'requestVerificationRequest', requestVerificationRequest)
            const localVarPath = `/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {string} stripeSignature 
         * @param {StripeRequest} stripeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripe: async (stripeSignature: string, stripeRequest: StripeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('stripe', 'stripeSignature', stripeSignature)
            // verify required parameter 'stripeRequest' is not null or undefined
            assertParamExists('stripe', 'stripeRequest', stripeRequest)
            const localVarPath = `/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stripe_add_purchase webhooks
         * @param {string} stripeSignature 
         * @param {StripeAddPurchaseRequest} stripeAddPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeAddPurchase: async (stripeSignature: string, stripeAddPurchaseRequest: StripeAddPurchaseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('stripeAddPurchase', 'stripeSignature', stripeSignature)
            // verify required parameter 'stripeAddPurchaseRequest' is not null or undefined
            assertParamExists('stripeAddPurchase', 'stripeAddPurchaseRequest', stripeAddPurchaseRequest)
            const localVarPath = `/stripe_add_purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeAddPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verification
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('verify', 'authorization', authorization)
            const localVarPath = `/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Inquiry
         * @param {InquiryRequest} inquiryRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiry(inquiryRequest: InquiryRequest, authorization?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiry(inquiryRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Registration
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequest: RegisterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a verification email
         * @param {RequestVerificationRequest} requestVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestVerification(requestVerificationRequest: RequestVerificationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestVerification(requestVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {string} stripeSignature 
         * @param {StripeRequest} stripeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripe(stripeSignature: string, stripeRequest: StripeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripe(stripeSignature, stripeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary stripe_add_purchase webhooks
         * @param {string} stripeSignature 
         * @param {StripeAddPurchaseRequest} stripeAddPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeAddPurchase(stripeSignature: string, stripeAddPurchaseRequest: StripeAddPurchaseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeAddPurchase(stripeSignature, stripeAddPurchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verification
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Inquiry
         * @param {InquiryRequest} inquiryRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiry(inquiryRequest: InquiryRequest, authorization?: string, options?: any): AxiosPromise<void> {
            return localVarFp.inquiry(inquiryRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registration
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest: RegisterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.register(registerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a verification email
         * @param {RequestVerificationRequest} requestVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerification(requestVerificationRequest: RequestVerificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestVerification(requestVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stripe webhooks
         * @param {string} stripeSignature 
         * @param {StripeRequest} stripeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripe(stripeSignature: string, stripeRequest: StripeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.stripe(stripeSignature, stripeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary stripe_add_purchase webhooks
         * @param {string} stripeSignature 
         * @param {StripeAddPurchaseRequest} stripeAddPurchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeAddPurchase(stripeSignature: string, stripeAddPurchaseRequest: StripeAddPurchaseRequest, options?: any): AxiosPromise<void> {
            return localVarFp.stripeAddPurchase(stripeSignature, stripeAddPurchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verification
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.verify(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Inquiry
     * @param {InquiryRequest} inquiryRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public inquiry(params: { inquiryRequest: InquiryRequest, authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['inquiryRequest'] = params['inquiryRequest']
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).inquiry(_params.inquiryRequest, _params.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registration
     * @param {RegisterRequest} registerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public register(params: { registerRequest: RegisterRequest,  }, options?: any) {
				const _params = {} as any;
				_params['registerRequest'] = params['registerRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).register(_params.registerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a verification email
     * @param {RequestVerificationRequest} requestVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public requestVerification(params: { requestVerificationRequest: RequestVerificationRequest,  }, options?: any) {
				const _params = {} as any;
				_params['requestVerificationRequest'] = params['requestVerificationRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).requestVerification(_params.requestVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stripe webhooks
     * @param {string} stripeSignature 
     * @param {StripeRequest} stripeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public stripe(params: { stripeSignature: string, stripeRequest: StripeRequest,  }, options?: any) {
				const _params = {} as any;
				_params['stripeSignature'] = params['stripeSignature']
				_params['stripeRequest'] = params['stripeRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).stripe(_params.stripeSignature, _params.stripeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary stripe_add_purchase webhooks
     * @param {string} stripeSignature 
     * @param {StripeAddPurchaseRequest} stripeAddPurchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public stripeAddPurchase(params: { stripeSignature: string, stripeAddPurchaseRequest: StripeAddPurchaseRequest,  }, options?: any) {
				const _params = {} as any;
				_params['stripeSignature'] = params['stripeSignature']
				_params['stripeAddPurchaseRequest'] = params['stripeAddPurchaseRequest']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).stripeAddPurchase(_params.stripeSignature, _params.stripeAddPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verification
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public verify(params: { authorization?: string,  }, options?: any) {
				const _params = {} as any;
				_params['authorization'] = params['authorization']
				// @ts-ignore
				_params.authorization = 'Bearer ' + ( params.authorization || Store.getInstance().getAccessToken())!
        return UserApiFp(this.configuration).verify(_params.authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


